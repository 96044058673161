import axios from 'axios';
import memberService from '../services/member.service';
import apiService from '../core/api-request.service';
/**
 * @author Solution Ladder <code@solutionladder.com>
 */
class ProductService {
    add = async product => {
        let token = memberService.getLoggedUser()?.jwtToken;
        try {
            return await axios.post(
                `${process?.env?.REACT_APP_SERVICE_URL}${process?.env?.REACT_APP_PRODUCTS}`,
                product, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            );
        } catch (ex) {
            console.log(ex);
            return false;
        }
    };

    /**
     * Method to return the list of the products.
     */
    get = async () => {
        let products = await apiService.get(
            `${process?.env?.REACT_APP_SERVICE_URL}${process?.env?.REACT_APP_PRODUCTS}`
        );
        return {length: products.data.length, products: products.data};
    }

        /**
     * Method to return the list of the products.
     */
    put = async (product, id) => {
        let updatedProduct = await apiService.put(
            `${process?.env?.REACT_APP_SERVICE_URL}${process?.env?.REACT_APP_PRODUCTS}/${id}`,
            product
        );
        console.log(updatedProduct);
        //return {length: products.data.length, products: products.data};
    }
}

const productService = new ProductService();
export default productService;