import apiService from "../core/api-request.service";
/**
 * Coupon service
 */
class CouponService {
  add = async (coupon) => {
    let response = await apiService.doActualPost(
      `${process?.env?.REACT_APP_SERVICE_URL}${process?.env?.REACT_APP_COUPON}`,
      coupon
    );
    return response;
  };
}

const couponService = new CouponService();
export default couponService;
