import memberService from "../../services/member.service";
import './menu-style.css'
import { Link } from "react-router-dom";


export default function Hamburger(props){
    
    const showAdminMenu = () => {
        return memberService.isMemberAdmin();
    };
    return (
        <div className="main-menu-mobile">
           <nav>
                  <ul>
                    <li>
                      <Link to="/">{props?.data?.i8tn?.en?.home}</Link>
                    </li>

                    <li>
                      <Link to="/shop">
                        {props?.data?.i8tn?.en?.collection}
                      </Link>
                    </li>
                    <li>
                      <Link to="/about">{props?.data?.i8tn?.en?.about}</Link>
                    </li>
                    <li>
                      <Link to="/contact">
                        {props?.data?.i8tn?.en?.contact}
                      </Link>
                    </li>
                    {showAdminMenu() && (
                      <li>
                        <div className="dropdown">
                          <button
                            className="btn btn-light btn-sm dropdown-toggle"
                            type="button"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            Admin
                          </button>
                          <div
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton"
                          >
                            <Link className="dropdown-item" to="/add-coupon">
                              Add Coupon
                            </Link>
                            <Link className="dropdown-item" to="/add-category">
                              Add Category
                            </Link>
                            <Link className="dropdown-item" to="/add-product">
                              Add Product
                            </Link>
                            <Link
                              className="dropdown-item"
                              to="/update-product"
                            >
                              Update Product
                            </Link>
                            <Link
                              className="dropdown-item"
                              to="/Search Member"
                            >
                              Search Member
                            </Link>
                            <a className="dropdown-item" to="/update-member">
                              Update Member
                            </a>
                          </div>
                        </div>
                      </li>
                    )}
                  </ul>
                  </nav>
        </div>
      );
}
