import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import ProductDetail from "../product/product-detail";

let style = {
  backImage: {
    backgroundImage: "url(https://unsplash.it/1920/800?image=640)",
  },
};
function Home(props) {
  const [newArrivals, setNewArrivals] = useState([]);
  const [frontCarousel, setFrontCarousel] = useState([]);

  useEffect(() => {
    getArrivalInfo();
    //checkIfUserLogged();
  }, []);

  const getArrivalInfo = async () => {
    //read data from server
    let response = await axios.get(
      `${process?.env?.REACT_APP_SERVICE_URL}${process?.env?.REACT_APP_ARRIVALS}`
    );
    if (response) {
      setNewArrivals(response.data);
    }
  };

  const cartSizeCallback = (cartSize) => {
    props.cartSizeCallback(cartSize);
  };

  /**
   * Check if the user has logged in and push the event back to
   * Header.
   */
  // const checkIfUserLogged = () => {
  //   console.log("user has logged and showing ");
  //   console.log(memberService.memberHasLogged());
  //   console.log(props);
  //   console.log(props.logged?.notifyLogging);
  //   //props.logged?.notifyLogging(memberService.memberHasLogged());
  // }

  return (
    <>
      <div className="slider-area">
        {props.data?.frontCarousel?.length > 0 &&
          props.data?.frontCarousel.map((fc) => {
            return (
              <div key={fc._id} className="slider-active nav-style-1">
                <div
                  className="single-slider-2 slider-height-2 d-flex align-items-center bg-img"
                  style={style.backImage}
                >
                  <div className="container">
                    <div className="row">
                      <div className="col-xl-6 col-lg-7 col-md-8 col-12 ml-auto">
                        <div className="slider-content-3 slider-animated-1 text-center">
                          <h3 className="animated">{fc.topLabel}</h3>
                          <h1 className="animated">{fc.mainLabel}</h1>
                          <p className="animated">{fc.lowerLabel}</p>
                          <div className="slider-btn btn-hover">
                            <Link className="animated" to={fc.linkTo}>
                              {fc.linkLabel}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
      <div className="suppoer-area pt-100 pb-60">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-6">
              <div className="support-wrap-2 mb-30 support-shape text-center">
                <div className="support-content-2">
                  <img
                    className="animated"
                    src="assets/img/icon-img/support-1.png"
                    alt=""
                  />
                  <h5>{props?.data?.i8tn?.en?.freeShipping}</h5>
                  <p>Online order has never been easier to get it to your door - for free</p>
                  
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6">
              <div className="support-wrap-2 mb-30 support-shape text-center">
                <div className="support-content-2">
                  <img
                    className="animated"
                    src="assets/img/icon-img/support-2.png"
                    alt=""
                  />
                  <h5>{props?.data?.i8tn?.en?.support247}</h5>
                  <p>Get our 24-7 support whenever you need it</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6">
              <div className="support-wrap-2 mb-30 text-center">
                <div className="support-content-2">
                  <img
                    className="animated"
                    src="assets/img/icon-img/support-3.png"
                    alt=""
                  />
                  <h5>{props?.data?.i8tn?.en?.moneyReturn}</h5>
                  <p>
                    No question asked, if you don't like it or change mind, you
                    can return it.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="product-area pb-60 section-padding-1">
        <div className="container-fluid">
          <div className="section-title-2 text-center mb-60">
            <h2>New Arrival</h2>
            <p>Recommended New Products for You</p>
          </div>
          <div className="custom-row">
            <ProductDetail
              products={newArrivals}
              cartSizeCallback={cartSizeCallback}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
