import { Redirect } from "react-router-dom";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import productService from '../../../services/product.service';
import './product.css'

/**
 * Admin page for editing product.
 * 
 * @author Solution Ladder <code@solutionladder.com>
 * 
 */
function EditProduct(props) {
    
    const [product, setProduct] = useState({});
    const [products, setProducts] = useState([]);
    const [alert, setAlert] = useState({
        style: "",
        message: ""
    });

    useEffect(async () => {
        let products = await productService.get();
        if (products && products.length > 0) {
            setProducts(products.products);
        }
    }, []);

    const { register, setValue, handleSubmit, formState: { errors } } = useForm({reValidateMode: "onChange"});

    /**
     * Generic function to handle all the input boxes. 
     * @param {} event 
     */
    const handleInput = event => {
        const key = event.target.getAttribute('data-field');
        const type = event.target.getAttribute('type');
        let newProduct = product;
        newProduct[key] = event.target.value;
        if (type == 'checkbox') {
            newProduct[key] = event.target.checked;
        }
        if (type == 'file') {
            newProduct[key] = event.target.files[0];
        }
        setProduct(newProduct);
    }

    /**
     * Add the edit product to the service.
     */
    const editHandler = () => {
        let file = new FormData();
        file.append('title', product.title);
        file.append('description', product.description);
        file.append('currentPrice', product.currentPrice);
        file.append('previousPrice', product.previousPrice);
        productService.put(file, product._id);
    }

    /**
     * Handle the population of the product upon the selection
     * @param {*} event 
     */
    const populateProduct = async (event) => {
        let productId = event.target.value;
        let product = products.find(product => product._id === productId);
        setValue('title', product.title);
        setValue('description', product.description);
        setValue('currentPrice', product.currentPrice);
        setValue('previousPrice', product.previousPrice);
        setProduct(product);
    };

    return (
        <>       
        {props.logged && <Redirect to="/" />}
        <div className="container">
            <div className="row">
                <div className="col-lg-7 col-md-12 ml-auto mr-auto">
                    <div className="login-register-wrapper">
                        <div className="login-register-tab-list nav">
                            {
                                products && Array.isArray(products) && products.length > 0  && 
                                <select onChange={populateProduct}>
                                    {
                                        products.map(
                                            product => {
                                                return <option key={product._id}
                                                value={product._id}>
                                                    {product.title}
                                                </option>
                                            }
                                        )
                                    }
                                </select>
                            }
                        </div>
                        <div className="login-register-tab-list nav">
                            <a data-toggle="tab" href="#lg2" className="">
                                <h4> Update Product </h4>
                            </a>
                        </div>
                        {/* <div className="tab-content"> */}
                            <div id="lg2" className="tab-pane">
                                <div className="login-form-container">
                                    <div className="login-register-form">
                                        <form onSubmit={handleSubmit(editHandler)}>
                                            <div className={'text-center alert ' + alert.style}>{alert.message}</div>
                                            <input type="text"
                                            {...register("title", {required: true})}
                                            data-field="title"
                                            onKeyUp={handleInput}
                                            placeholder="Title" />
                                            {errors.title && <span className={'error'}>Title is required</span>}
                                            <textarea data-field="description"
                                            {...register("description", {required: true})}
                                            onChange={handleInput} placeholder="Description"></textarea>
                                            {errors.description && <span className={'error'}>Description is required</span>}
                                            <input type="text" 
                                            {...register("currentPrice", {required: true})}
                                            data-field="currentPrice" onChange={handleInput} placeholder="Price" />
                                            {errors.currentPrice && <span className={'error'}>Price is required</span>}
                                            <input type="text"
                                            {...register("previousPrice", {required: true})}
                                            data-field="previousPrice" onChange={handleInput} placeholder="Previous Price" />
                                            {errors.previousPrice && <span className={'error'}>Previous Price is required</span>}
                                            <input type="file" data-field="picture" onChange={handleInput} name="picture" placeholder="Picture" />
                                            <div className="button-box">
                                                <button type="submit"><span className="transform">Edit Product</span></button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        {/* </div> */}
                    </div>
                </div>
            </div>
        </div>
    </>
    
        )
}

export default EditProduct;