import { useState, useEffect } from "react";
import apiService from "../../core/api-request.service";
import ProductDetail from "./product-detail";

function Shop(props) {
  const ASCENDING = "ascending";
  const DESCENDING = "descending";

  const [newProducts, setNewProducts] = useState([]);
  const [productLength, setProductLength] = useState(0);
  useEffect(() => {
    getNewProducts();
    getProductLength();
  }, []);

  /**
   * Count products with picture only.
   * @todo, make the product to read from the variable
   *
   */
  const getProductLength = (attribute = "picture") => {
    //delay is affecting it??
    setProductLength(newProducts?.filter((product) => product.picture)?.length);
  };

  const getNewProducts = async () => {
    //read data from server
    try {
      let products = await apiService.get(
        `${process?.env?.REACT_APP_SERVICE_URL}${process?.env?.REACT_APP_PRODUCTS}`
      );
      setNewProducts(products.data);
    } catch (error) {
      console.log(error);
    }
  };
  const sortItem = (e) => {
    const value = e.target.value;

    // Sorting data
    let sortUp = 1;
    let sortDown = -1;
    if (value == ASCENDING) {
      sortUp = -1;
      sortDown = 1;
    }

    const sortData = [...newProducts].sort((a, b) => {
      const nameA = a.title.toUpperCase();
      const nameB = b.title.toUpperCase();
      if (nameA < nameB) {
        return sortUp;
      }
      if (nameA > nameB) {
        return sortDown;
      }
      return 0;
    });
    setNewProducts(sortData);
  };

  /**
   * Event handler for the search box
   * @param {*} event
   */
  const searchProduct = async (event) => {
    let title = event.target.value;
    try {
      let products = await apiService.get(
        `${process?.env?.REACT_APP_SERVICE_URL}${process?.env?.REACT_APP_PRODUCTS}?title=${title}`
      );
      setNewProducts(products?.data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div className="shop-area pt-95 pb-100">
        <div className="container">
          <div className="row flex-row-reverse">
            <div className="col-lg-9">
              <div className="shop-top-bar">
                <div className="select-shoing-wrap">
                  <div className="shop-select">
                    <select onChange={sortItem}>
                      <option value="">Sort by arrival</option>
                      <option value={ASCENDING}>A to Z</option>
                      <option value={DESCENDING}> Z to A</option>
                      <option value="">In stock</option>
                    </select>
                  </div>
                  Showing 1-{productLength} of {productLength} result
                </div>
                <div className="shop-tab nav">
                  <a className="active" href="#shop-1" data-toggle="tab">
                    <i className="fa fa-table"></i>
                  </a>
                  <a href="#shop-2" data-toggle="tab">
                    <i className="fa fa-list-ul"></i>
                  </a>
                </div>
              </div>

              <div className="shop-bottom-area mt-35">
                <div className="tab-content jump">
                  <div id="shop-1" className="tab-pane active">
                    <div className="row">
                      <ProductDetail products={newProducts} />
                    </div>
                  </div>
                  <div id="shop-2" className="tab-pane">
                    <div className="shop-list-wrap mb-30">
                      <div className="row">
                        <div className="col-xl-4 col-lg-5 col-md-5 col-sm-6">
                          <div className="product-wrap">
                            <div className="product-img">
                              <a href="product-details.html">
                                <img
                                  className="default-img"
                                  src="assets/img/product/pro-1.jpg"
                                  alt=""
                                />
                                <img
                                  className="hover-img"
                                  src="assets/img/product/pro-1-1.jpg"
                                  alt=""
                                />
                              </a>
                              <span className="pink">-10%</span>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-8 col-lg-7 col-md-7 col-sm-6">
                          <div className="shop-list-content">
                            <h3>
                              <a href="#">Products Name Here</a>
                            </h3>
                            <div className="product-list-price">
                              <span>$ 60.00</span>
                              <span className="old">$ 90.00</span>
                            </div>

                            <div className="rating-review">
                              <div className="product-list-rating">
                                <i className="fa fa-star-o yellow"></i>
                                <i className="fa fa-star-o yellow"></i>
                                <i className="fa fa-star-o yellow"></i>
                                <i className="fa fa-star-o"></i>
                                <i className="fa fa-star-o"></i>
                              </div>
                              <a href="#">3 Reviews</a>
                            </div>
                            <p>
                              Lorem ipsum dolor sit amet, consecteto adipisic
                              elit eiusm tempor incidid ut labore et dolore
                              magna aliqua Ut enim ad minim{" "}
                            </p>
                            <div className="shop-list-btn btn-hover">
                              <a href="#">ADD TO CART</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="shop-list-wrap mb-30">
                      <div className="row">
                        <div className="col-xl-4 col-lg-5 col-md-5 col-sm-6">
                          <div className="product-wrap">
                            <div className="product-img">
                              <a href="product-details.html">
                                <img
                                  className="default-img"
                                  src="assets/img/product/pro-2.jpg"
                                  alt=""
                                />
                                <img
                                  className="hover-img"
                                  src="assets/img/product/pro-2-1.jpg"
                                  alt=""
                                />
                              </a>
                              <span className="purple">arrival</span>{" "}
                              {/*it was new*/}
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-8 col-lg-7 col-md-7 col-sm-6">
                          <div className="shop-list-content">
                            <h3>
                              <a href="#">Products Name Here</a>
                            </h3>
                            <div className="product-list-price">
                              <span>$ 60.00</span>
                            </div>
                            <div className="rating-review">
                              <div className="product-list-rating">
                                <i className="fa fa-star-o yellow"></i>
                                <i className="fa fa-star-o yellow"></i>
                                <i className="fa fa-star-o yellow"></i>
                                <i className="fa fa-star-o"></i>
                                <i className="fa fa-star-o"></i>
                              </div>
                              <a href="#">3 Reviews</a>
                            </div>
                            <p>
                              Lorem ipsum dolor sit amet, consecteto adipisic
                              elit eiusm tempor incidid ut labore et dolore
                              magna aliqua Ut enim ad minim{" "}
                            </p>
                            <div className="shop-list-btn btn-hover">
                              <a href="#">ADD TO CART</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="shop-list-wrap mb-30">
                      <div className="row">
                        <div className="col-xl-4 col-lg-5 col-md-5 col-sm-6">
                          <div className="product-wrap">
                            <div className="product-img">
                              <a href="product-details.html">
                                <img
                                  className="default-img"
                                  src="assets/img/product/pro-3.jpg"
                                  alt=""
                                />
                                <img
                                  className="hover-img"
                                  src="assets/img/product/pro-3-1.jpg"
                                  alt=""
                                />
                              </a>
                              <span className="pink">-20%</span>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-8 col-lg-7 col-md-7 col-sm-6">
                          <div className="shop-list-content">
                            <h3>
                              <a href="product-details.html">
                                Products Name Here
                              </a>
                            </h3>
                            <div className="product-list-price">
                              <span>$ 30.00</span>
                              <span className="old">$ 50.00</span>
                            </div>
                            <div className="rating-review">
                              <div className="product-list-rating">
                                <i className="fa fa-star-o yellow"></i>
                                <i className="fa fa-star-o yellow"></i>
                                <i className="fa fa-star-o yellow"></i>
                                <i className="fa fa-star-o"></i>
                                <i className="fa fa-star-o"></i>
                              </div>
                              <a href="#">3 Reviews</a>
                            </div>
                            <p>
                              Lorem ipsum dolor sit amet, consecteto adipisic
                              elit eiusm tempor incidid ut labore et dolore
                              magna aliqua Ut enim ad minim{" "}
                            </p>
                            <div className="shop-list-btn btn-hover">
                              <a href="#">ADD TO CART</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="shop-list-wrap mb-30">
                      <div className="row">
                        <div className="col-xl-4 col-lg-5 col-md-5 col-sm-6">
                          <div className="product-wrap">
                            <div className="product-img">
                              <a href="product-details.html">
                                <img
                                  className="default-img"
                                  src="assets/img/product/pro-7.jpg"
                                  alt=""
                                />
                                <img
                                  className="hover-img"
                                  src="assets/img/product/pro-4-1.jpg"
                                  alt=""
                                />
                              </a>
                              <span className="purple">arrival</span>
                              {/*it was new*/}
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-8 col-lg-7 col-md-7 col-sm-6">
                          <div className="shop-list-content">
                            <h3>
                              <a href="product-details.html">
                                Products Name Here
                              </a>
                            </h3>
                            <div className="product-list-price">
                              <span>$ 70.00</span>
                            </div>
                            <div className="rating-review">
                              <div className="product-list-rating">
                                <i className="fa fa-star-o yellow"></i>
                                <i className="fa fa-star-o yellow"></i>
                                <i className="fa fa-star-o yellow"></i>
                                <i className="fa fa-star-o"></i>
                                <i className="fa fa-star-o"></i>
                              </div>
                              <a href="#">3 Reviews</a>
                            </div>
                            <p>
                              Lorem ipsum dolor sit amet, consecteto adipisic
                              elit eiusm tempor incidid ut labore et dolore
                              magna aliqua Ut enim ad minim{" "}
                            </p>
                            <div className="shop-list-btn btn-hover">
                              <a href="#">ADD TO CART</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pro-pagination-style text-center mt-30">
                  <ul>
                    <li>
                      <a className="prev" href="#">
                        <i className="fa fa-angle-double-left"></i>
                      </a>
                    </li>
                    <li>
                      <a className="active" href="#">
                        1
                      </a>
                    </li>
                    <li>
                      <a href="#">2</a>
                    </li>
                    <li>
                      <a className="next" href="#">
                        <i className="fa fa-angle-double-right"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-lg-3">
              <div className="sidebar-style mr-30">
                <div className="sidebar-widget">
                  <h4 className="pro-sidebar-title">Search </h4>
                  <div className="pro-sidebar-search mb-50 mt-25">
                    <form className="pro-sidebar-search-form" action="#">
                      <input
                        onKeyUp={searchProduct}
                        type="text"
                        placeholder="Search here..."
                      />
                      <button>
                        <i className="pe-7s-search"></i>
                      </button>
                    </form>
                  </div>
                </div>
                <div className="sidebar-widget">
                  <h4 className="pro-sidebar-title">Refine By </h4>
                  <div className="sidebar-widget-list mt-30">
                    <ul>
                      <li></li>
                      <li>
                        <div className="sidebar-widget-list-left">
                          <input type="checkbox" value="" />{" "}
                          <a href="#">
                            New <span>4</span>
                          </a>
                          <span className="checkmark"></span>
                        </div>
                      </li>
                      <li>
                        <div className="sidebar-widget-list-left">
                          <input type="checkbox" value="" />{" "}
                          <a href="#">
                            In Stock <span>4</span>{" "}
                          </a>
                          <span className="checkmark"></span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="sidebar-widget mt-45">
                  <h4 className="pro-sidebar-title">Filter By Price </h4>
                  <div className="price-filter mt-10">
                    <div className="price-slider-amount">
                      <input
                        type="text"
                        id="amount"
                        name="price"
                        placeholder="Add Your Price"
                      />
                    </div>
                    <div id="slider-range"></div>
                  </div>
                </div>
                <div className="sidebar-widget mt-50">
                  <h4 className="pro-sidebar-title">Colour </h4>
                  <div className="sidebar-widget-list mt-20">
                    <ul>
                      <li>
                        <div className="sidebar-widget-list-left">
                          <input type="checkbox" value="" />{" "}
                          <a href="#">
                            Green <span>4</span>{" "}
                          </a>
                          <span className="checkmark"></span>
                        </div>
                      </li>
                      <li>
                        <div className="sidebar-widget-list-left">
                          <input type="checkbox" value="" />{" "}
                          <a href="#">
                            Cream <span>4</span>{" "}
                          </a>
                          <span className="checkmark"></span>
                        </div>
                      </li>
                      <li>
                        <div className="sidebar-widget-list-left">
                          <input type="checkbox" value="" />{" "}
                          <a href="#">
                            Blue <span>4</span>{" "}
                          </a>
                          <span className="checkmark"></span>
                        </div>
                      </li>
                      <li>
                        <div className="sidebar-widget-list-left">
                          <input type="checkbox" value="" />{" "}
                          <a href="#">
                            Black <span>4</span>{" "}
                          </a>
                          <span className="checkmark"></span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="sidebar-widget mt-40">
                  <h4 className="pro-sidebar-title">Size </h4>
                  <div className="sidebar-widget-list mt-20">
                    <ul>
                      <li>
                        <div className="sidebar-widget-list-left">
                          <input type="checkbox" value="" /> <a href="#">XL</a>
                          <span className="checkmark"></span>
                        </div>
                      </li>
                      <li>
                        <div className="sidebar-widget-list-left">
                          <input type="checkbox" value="" /> <a href="#">L</a>
                          <span className="checkmark"></span>
                        </div>
                      </li>
                      <li>
                        <div className="sidebar-widget-list-left">
                          <input type="checkbox" value="" /> <a href="#">SM</a>
                          <span className="checkmark"></span>
                        </div>
                      </li>
                      <li>
                        <div className="sidebar-widget-list-left">
                          <input type="checkbox" value="" /> <a href="#">XXL</a>
                          <span className="checkmark"></span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="sidebar-widget mt-50">
                  <h4 className="pro-sidebar-title">Tag </h4>
                  <div className="sidebar-widget-tag mt-25">
                    <ul>
                      <li>
                        <a href="#">Clothing</a>
                      </li>
                      <li>
                        <a href="#">Accessories</a>
                      </li>
                      <li>
                        <a href="#">For Men</a>
                      </li>
                      <li>
                        <a href="#">Women</a>
                      </li>
                      <li>
                        <a href="#">Fashion</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Shop;
