import apiService from '../core/api-request.service';
/**
 * Category service
 * @author Solution Ladder <code@solutionladder.com>
 */
class CategoryService {
    add = async category => {
        let response = await apiService.doActualPost(
            `${process?.env?.REACT_APP_SERVICE_URL}${process?.env?.REACT_APP_CATEGORY}`,
            category
        );
        return response;
    }
}

const categoryService = new CategoryService();
export default categoryService;