import { Redirect } from "react-router-dom";
import { useState } from "react";
import { useForm } from "react-hook-form";
import productService from "../../../services/product.service";
import "./product.css";

/**
 * Admin page for adding new product.
 *
 * @author Solution Ladder <code@solutionladder.com>
 *
 */
function AddProduct(props) {
  const [product, setProduct] = useState({});

  const [alert, setAlert] = useState({
    style: "",
    message: "",
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ reValidateMode: "onChange" });

  const [selectedFiles, setSelectedFiles] = useState([{}]);

  const handleFileSelect = (e, index) => {
    const { name, value } = e.target;
    const files = [...selectedFiles];
    files[index][name] = value;
    setSelectedFiles(files);
  };

  // handle click event of the Add Image button
  const handleAddClick = () => {
    setSelectedFiles([...selectedFiles, {}]);
  };

  // Function to delete the added image panel
  const handleDelete = (index) => {
    const updatedFiles = selectedFiles.filter((file, i) => i !== index);
    setSelectedFiles(updatedFiles);
  };

  /**
   * Generic function to handle all the input boxes.
   * @param {} event
   */
  const handleInput = (event) => {
    const key = event.target.getAttribute("data-field");
    const type = event.target.getAttribute("type");
    let newProduct = product;
    newProduct[key] = event.target.value;
    if (type == "checkbox") {
      newProduct[key] = event.target.checked;
    }
    if (type == "file") {
      newProduct[key] = event.target.files[0];
    }
    setProduct(newProduct);
  };

  /**
   * Add the new product to the service.
   */
  const addHandler = async () => {
    let file = new FormData();
    file.append("file", product.picture);
    file.append("title", product.title);
    file.append("description", product.description);
    file.append("currentPrice", product.currentPrice);
    file.append("previousPrice", product.previousPrice);
    file.append("tags", product.tags);
    let response = await productService.add(file);
    console.log(response);
    if (response && response.data?.errors == null) {
      setAlert({ style: "alert alert-success", message: "Successfully Added" });
    } else {
      setAlert({ style: "alert alert-danger", message: "It didn't go thru." });
    }
    //check for the success message before wiping the data.
    if (response && response.data?.errors == null) {
      reset({
        title: "",
        description: "",
        currentPrice: "",
        previousPrice: "",
        tags: "",
        file: "",
      });
    }
  };
  return (
    <>
      {props.logged && <Redirect to="/" />}
      <div className="container">
        <div className="row">
          <div className="col-lg-7 col-md-12 ml-auto mr-auto">
            <div className="login-register-wrapper">
              <div className="login-register-tab-list nav">
                <a data-toggle="tab" href="#lg2" className="">
                  <h4> Add Product </h4>
                </a>
              </div>
              {/* <div className="tab-content"> */}
              <div id="lg2" className="tab-pane">
                <div className="login-form-container">
                  <div className="login-register-form">
                    <form onSubmit={handleSubmit(addHandler)}>
                      <div className={"text-center alert " + alert.style}>
                        {alert.message}
                      </div>
                      <input
                        type="text"
                        {...register("title", { required: true })}
                        data-field="title"
                        onKeyUp={handleInput}
                        placeholder="Title"
                      />
                      {errors.title && (
                        <span className={"error"}>Title is required</span>
                      )}
                      <textarea
                        data-field="description"
                        {...register("description", { required: true })}
                        onChange={handleInput}
                        placeholder="Description"
                      ></textarea>
                      {errors.description && (
                        <span className={"error"}>Description is required</span>
                      )}
                      <input
                        type="text"
                        {...register("currentPrice", { required: true })}
                        data-field="currentPrice"
                        onChange={handleInput}
                        placeholder="Price"
                      />
                      {errors.currentPrice && (
                        <span className={"error"}>Price is required</span>
                      )}
                      <input
                        type="text"
                        {...register("previousPrice", { required: true })}
                        data-field="previousPrice"
                        onChange={handleInput}
                        placeholder="Previous Price"
                      />
                      {errors.previousPrice && (
                        <span className={"error"}>
                          Previous Price is required
                        </span>
                      )}
                      <textarea
                        data-field="tags"
                        {...register("tags", { required: true })}
                        onChange={handleInput}
                        placeholder="Tags"
                      ></textarea>
                      {errors.tags && (
                        <span className={"error"}>Tag is required</span>
                      )}
                      {selectedFiles.map((file, i) => {
                        return (
                          <div
                            key={i}
                            className="button-box"
                            style={{
                              position: "relative",
                              margin: "10px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                              }}
                            >
                              <input
                                style={{
                                  width: "100%",
                                  padding: "5px",
                                }}
                                type="file"
                                multiple
                                name="image"
                                value={file.image}
                                placeholder="Picture"
                                onChange={(e) => handleFileSelect(e, i)}
                              />
                              {i !== 0 && (
                                <button
                                  style={{
                                    width: "45px",
                                    height: "45px",
                                    padding: "2px",
                                  }}
                                  className="fa fa-times"
                                  onClick={() => handleDelete(i)}
                                ></button>
                              )}
                            </div>
                            {selectedFiles.length - 1 === i && (
                              <button
                                className="styled-button"
                                onClick={handleAddClick}
                                style={StyledButton}
                                type="button"
                              >
                                Add Images
                              </button>
                            )}
                          </div>
                        );
                      })}
                      <div className="button-box">
                        <button type="submit">
                          <span className="transform">Add Product</span>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const StyledButton = {
  position: "absolute",
  right: "0",
  margin: "5px",
};

export default AddProduct;
