import { useEffect, useState } from "react";
/**
 * List of the orders in the given cart list.
 */
import cartService from "../../services/cart.service";

function CartList(props) {
  //populate the list of cart for the current user if there is any.
  const [orders, setOrders] = useState([]);
  const [productTotal, setProductTotal] = useState(0);
  const [shipping, setShipping] = useState(0);
  const [coupon, setCoupon] = useState({
    name: "",
    amount: 20,
    applied: false,
  });
  const [showUpdate, setShowUpdate] = useState(false);
  const DISPLAY_UPDATE_FOR = 2000; //show update only for 2 seconds.

  useEffect(() => {
    async function fetchCart() {
      try {
        const cart = await cartService.get();
        if (cart && cart.data.length > 0) {
          const gTotal = cart?.data?.reduce(
            (total, order) => total + order.currentPrice * order.quantity,
            0
          );
          setOrders(cart.data);
          setProductTotal(parseFloat(gTotal.toFixed(2)));  
        }
      } catch (error) {
        console.log(error)
      }
    }
    fetchCart();
  }, []);
 
  /**
   * Update total as needed.
   * @see usage.
   */
  const updateTotalPrice = () => {
    setProductTotal(() => {
      const totalPrice = orders?.reduce(
        (total, order) => total + order.currentPrice * order.quantity,
        0
      );
      return parseFloat(totalPrice.toFixed(2));
    });
  }

  /**
   * Handle the selection of the shipping expenses.
   * @param {*} event
   */
  const handleShipping = (event) => {
    setShipping(parseFloat(event.target.value));
  };

  /**
   * Handle increment/decrement of quantity
   */
  const updateQuantity = async (val, productIndex) => {
    val = parseInt(val);
    if (isNaN(val)) return;
  
    let newOrders = orders.map((order) => ({ ...order })); // clone it.
  
    if (newOrders[productIndex].quantity + val > 0) {
      // allow only quantity above 0
      newOrders[productIndex].quantity += val;
  
      try {
        let response = await cartService.updateQuantity(
          newOrders[productIndex].quantity,
          newOrders[productIndex].id
        );
  
        if (response) {
          setShowUpdate(true);
          updateTotalPrice();
          setTimeout(() => setShowUpdate(false), DISPLAY_UPDATE_FOR);
          
          // Calculate the updated cart size based on the quantities of items in the cart
          let updatedCartSize = newOrders.reduce(
            (total, order) => total + order.quantity,
            0
          );
          // parent component willreceive and handle the updated cart size value, 
          // Update the cart size by invoking the callback function from props
          props.cartSizeCallback(updatedCartSize);
        }
      } catch (error) {
        console.log(error);
      }
      setOrders(newOrders);
    }
  };
  /**
   * Handle value change on quantity input box.
   * @param {*} val
   * @param {*} productIndex
   */
  const handleQuantityText = (val, productIndex) => {
    val = parseInt(val);
    if (isNaN(val) && val > 0) return;

    let newOrders = orders.map((order) => order); //clone it.
    newOrders[productIndex].quantity = val;
    setOrders(newOrders);
  };

  /**
   * Event handler to register the coupon name/title
   * @param {*} event
   */
  const handleCouponName = (event) => {
    let updatedCoupon = { ...coupon };
    updatedCoupon.name = "";
    if (event.target.value.length >= 3) {
      updatedCoupon.name = event.target.value;
    }
    setCoupon(updatedCoupon);
  };

  /**
   * Event handler for the coupon implementation
   * @param {*} event
   */
  const handleCouponSubmit = (event) => {
    event.preventDefault();
    if (coupon.name) {
      coupon.applied = true;
      setCoupon(coupon);
      setProductTotal(productTotal - coupon.amount);
    }
  };
  return (
    <>
      <div className="cart-main-area pt-90 pb-100">
        <div className="container">
          <h3 className="cart-page-title">Your order is almost ready</h3>
          {showUpdate && (
            <div className="alert alert-success">
              Successfully Updated Quantity
            </div>
          )}
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <form action="#">
                <div className="table-content table-responsive cart-table-content">
                  <table>
                    <thead>
                      <tr>
                        <th>Image</th>
                        <th>Product Name</th>
                        <th>Unit Price</th>
                        <th>Qty</th>
                        <th>Subtotal</th>
                        <th>action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orders?.map((order, index) => {
                        return (
                          <tr key={index} >
                            <td className="product-thumbnail">
                              <a href="#">
                                <img
                                  style={{ width: "70%" }}
                                  src={order.picture}
                                  alt=""
                                />
                              </a>
                            </td>
                            <td className="product-name">
                              <a href="#">{order.title}</a>
                            </td>
                            <td className="product-price-cart">
                              <span className="amount">
                                ${order.currentPrice}
                              </span>
                            </td>
                            <td className="product-quantity">
                              <div className="cart-plus-minus">
                                <div
                                  className="dec qtybutton"
                                  onClick={() => updateQuantity(-1, index)}
                                >
                                  -
                                </div>
                                <input
                                  className="cart-plus-minus-box"
                                  type="text"
                                  name="qtybutton"
                                  onChange={(event) =>
                                    handleQuantityText(
                                      event.target.value,
                                      index
                                    )
                                  }
                                  value={order.quantity}
                                />
                                <div
                                  className="inc qtybutton"
                                  onClick={() => updateQuantity(1, index)}
                                >
                                  +
                                </div>
                              </div>
                            </td>
                            <td className="product-subtotal">
                              $
                              {(order.currentPrice * order.quantity).toFixed(2)}
                            </td>
                            <td className="product-remove">
                              <a href="#">
                                <i className="fa fa-pencil"></i>
                              </a>
                              <a href="#">
                                <i className="fa fa-times"></i>
                              </a>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="cart-shiping-update-wrapper">
                      <div className="cart-shiping-update">
                        <a href="#">Continue Shopping</a>
                      </div>
                      <div className="cart-clear">
                        <button>Update Shopping Cart</button>
                        <a href="#">Clear Shopping Cart</a>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <div className="row">
                <div className="col-lg-4 col-md-6">
                  <div className="cart-tax">
                    <div className="title-wrap">
                      <h4 className="cart-bottom-title section-bg-gray">
                        Estimate Shipping And Tax
                      </h4>
                    </div>
                    <div className="tax-wrapper">
                      <p>Enter your destination to get a shipping estimate.</p>
                      <div className="tax-select-wrapper">
                        <div className="tax-select">
                          <label>* Country</label>
                          <select className="email s-email s-wid">
                            <option>Bangladesh</option>
                            <option>Albania</option>
                            <option>Åland Islands</option>
                            <option>Afghanistan</option>
                            <option>Belgium</option>
                          </select>
                        </div>
                        <div className="tax-select">
                          <label>* Region / State</label>
                          <select className="email s-email s-wid">
                            <option>Bangladesh</option>
                            <option>Albania</option>
                            <option>Åland Islands</option>
                            <option>Afghanistan</option>
                            <option>Belgium</option>
                          </select>
                        </div>
                        <div className="tax-select">
                          <label>* Zip/Postal Code</label>
                          <input type="text" />
                        </div>
                        <button className="cart-btn-2" type="submit">
                          Get A Quote
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="discount-code-wrapper">
                    <div className="title-wrap">
                      <h4 className="cart-bottom-title section-bg-gray">
                        Use Coupon Code
                      </h4>
                    </div>
                    <div className="discount-code">
                      <p>coupone is hjere {coupon.name}</p>
                      <p>Got a coupon? Add it here for discount.</p>
                      <form onSubmit={handleCouponSubmit}>
                        <input
                          type="text"
                          required=""
                          name="name"
                          onKeyUp={handleCouponName}
                        />
                        <button
                          style={{
                            pointerEvents: coupon.name != "" ? "all" : "none",
                          }}
                          className="cart-btn-2"
                        >
                          Apply coupon
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12">
                  <div className="grand-totall">
                    <div className="title-wrap">
                      <h4 className="cart-bottom-title section-bg-gary-cart">
                        Cart Total
                      </h4>
                    </div>
                    {coupon.applied && (
                      <h5>
                        Coupon Applied <span>${coupon.amount}</span>
                      </h5>
                    )}
                    <h5>
                      Total products <span>${productTotal}</span>
                    </h5>
                    <div className="total-shipping">
                      <h5>
                        Total shipping
                        <span style={{ float: "none", color: "red" }}>*</span>
                      </h5>
                      <ul>
                        <li>
                          <input
                            onChange={handleShipping}
                            name="shipping"
                            type="radio"
                            value={20}
                          />{" "}
                          Standard{" "}
                          <span> {props.data?.shipping?.standard}</span>
                        </li>
                        <li>
                          <input
                            onChange={handleShipping}
                            name="shipping"
                            type="radio"
                            value={30}
                          />{" "}
                          Express <span> {props.data?.shipping?.express}</span>
                        </li>
                      </ul>
                    </div>
                    <h4 className="grand-totall-title">
                      Grand Total <span>${productTotal + shipping}</span>
                    </h4>
                    {
                      <a
                        href="#"
                        style={{ pointerEvents: shipping ? "all" : "none" }}
                      >
                        Proceed to Checkout
                      </a>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CartList;
