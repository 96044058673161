import apiRequestService from '../core/api-request.service';
import memberService from './member.service';

/**
 * Service for Cart related task.
 * 
 * @author Solution Ladder <code@solutionladder.com>
 */
class CartService {

    /**
     * Send request to add order to the cart.
     * @param {*} order 
     */
    add = async order => {
        try {
            let loggedUser = memberService.getLoggedUser();
            if ( !loggedUser ) {
                return false;
            }
            const response = await apiRequestService.post(
                `${process?.env?.REACT_APP_SERVICE_URL}${process?.env?.REACT_APP_CART}`,
                order, true
            );

            if (!response.success) {
                return response;
            }
            //update the cartSize.
            loggedUser.cartSize = response?.data?.cartSize;
            return response;
        } catch (ex) {
            console.log(ex);
        }
    }

    get = async () => {
        try {
            const response = await apiRequestService.get(
                `${process?.env?.REACT_APP_SERVICE_URL}${process?.env?.REACT_APP_CART}`,
                true
            );
            return response;
        } catch (error) {
            console.log(error)
        }
    }

    /**
     * Update the quantity of the cart's order.
     * @param {*} quantity 
     * @param {*} orderId 
     */
    updateQuantity = async(quantity, orderId) => {
        try {
            const response = await apiRequestService.put(
                `${process?.env?.REACT_APP_SERVICE_URL}${process?.env?.REACT_APP_CART}/order/${orderId}`,
                {quantity}, true
            );
            return response;
        } catch (error) {
            console.log(error)
        }
    }
}

const cartService = new CartService();
export default cartService;