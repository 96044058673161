import React, { useEffect, useState } from "react";
import memberService from "../../services/member.service";

function Profile(props) {

  const [loggedUser, setLoggedUser] = useState({});
  const [isDisabled, setIsDisabled] = useState(true);
  const [title, setTitle] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [gender, setGender] = useState('');
  const [hasChanged, setHasChanged] = useState(false);

  useEffect(() => {
    //get logged user information.
    const lUser = memberService.getLoggedUser();
    if (lUser) {
      setLoggedUser(memberService.getLoggedUser());
      setTitle(memberService.getLoggedUser().title)
      setFirstName(memberService.getLoggedUser().firstName)
      setLastName(memberService.getLoggedUser().lastName)
      setPhone(memberService.getLoggedUser().phone)
      setCity(memberService.getLoggedUser().city)
      setState(memberService.getLoggedUser().state)
      setGender(memberService.getLoggedUser().gender)
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    };
  }, []);
  useEffect(() => {
    const isThereNewChange =
      loggedUser.title === title &&
      loggedUser.firstName === firstName &&
      loggedUser.lastName === lastName &&
      loggedUser.phone === phone &&
      loggedUser.city === city &&
      loggedUser.state === state &&
      loggedUser.gender === gender
    setIsDisabled(isThereNewChange);
  }, [title, firstName, lastName, phone, city, state, gender, loggedUser]);

  function handleSubmit(e) {
    e.preventDefault();
    console.log("submit");
  }
  const submitButtonStyle = {
    cursor: isDisabled ? 'default' : 'pointer',
    color: isDisabled ? '#666' : '#333',
    border: "none",
    padding: '11px 30px',
    
  };
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-lg-7 col-md-12 ml-auto mr-auto">
            <div className="login-register-wrapper">
              <div className="login-register-tab-list nav">
                <h4> Edit Profile </h4>
              </div>
              <div id="lg2" className="tab-pane">
                <div className="login-form-container">
                  <div className="login-register-form">
                    <form onSubmit={handleSubmit}>
                      <div className={"text-center alert " + alert.style}>
                        {alert.message}
                      </div>
                      <input
                        className="title"
                        type="text"
                        required
                        value={title}
                        onChange={(e) => {
                          setTitle(e.target.value)
                        }}
                        placeholder="Title"
                      />
                      <input
                        className="firstName"
                        type="text"
                        required
                        value={firstName}
                        onChange={(e) => {
                          setFirstName(e.target.value)
                        }}
                        placeholder="First Name"
                      />
                      <input
                        className="lastName"
                        type="text"
                        required
                        value={lastName}
                        onChange={(e) => {
                          setLastName(e.target.value)
                        }}
                        placeholder="Last Name"
                      />
                      <input
                        className="phoneNumber"
                        type="text"
                        required
                        onChange={(e) => {
                          setPhone(e.target.value)
                        }}
                        placeholder="Phone Number"
                      />
                      <input className="city"
                        type="text"
                        onChange={(e) => {
                          setCity(e.target.value)
                        }}
                        placeholder="City" />
                      <input
                        className="state"
                        type="text"
                        onChange={(e) => {
                          setState(e.target.value)
                        }}
                        placeholder="State"
                      />
                      <div className="gender">
                        Male
                        <input type="radio" value="M" name="gender" onChange={(e) => {
                          e.target.checked && setGender('Male')
                        }} />
                        Female
                        <input type="radio" value="F" name="gender" onChange={(e) => {
                          e.target.checked && setGender('Female')
                        }} />
                      </div>
                      <div className="button-box">
                        <button
                          disabled={!hasChanged}
                          className={hasChanged ? "active" : ""}
                          type="submit"
                          onClick={handleSubmit}
                          style={submitButtonStyle}
                        >
                          <span>Submit</span>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Profile;
