function Returns(props) {
  return (
    <>
      <div className="welcome-area pt-100 pb-95">
        <div className="container">
          <div className="welcome-content text-center">
            <p>
              Rules and regulations coming soon
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Returns;
