function About(props) {
  return (
    <>
      <div className="welcome-area pt-100 pb-95">
        <div className="container">
          <div className="welcome-content text-center">
            <h5>Who Are We</h5>
            <h1>Welcome To Telalaki</h1>
            <p>
              Select items that fit to your family and to your budget as the
              same time
            </p>
          </div>
        </div>
      </div>
      <div className="banner-area pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-4">
              <div className="single-banner mb-30">
                <a href="product-details.html">
                <img src="assets/img/about-us/vision.jpg" alt="" />
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-4">
              <div className="single-banner mb-30">
                <a href="product-details.html">
                <img src="assets/img/about-us/mission.jpg" alt="" />
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-4">
              <div className="single-banner mb-30">
                <a href="product-details.html">
                  <img src="assets/img/about-us/goal.jpg" alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="about-mission-area pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-4">
              <div className="single-mission mb-30">
                <h3>Our vision</h3>
                <p>
                  Making the transaction of items smooth for everyone regardless
                  of 'where'.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4">
              <div className="single-mission mb-30">
                <h3>Our mission </h3>
                <p>
                  To make sure we are delivering all the needs for our
                  customers.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4">
              <div className="single-mission mb-30">
                <h3>Our goal</h3>
                <p>Happy customers.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="funfact-area bg-gray-3 pt-100 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-count text-center mb-30">
                <div className="count-icon">
                  <i className="pe-7s-rocket"></i>
                </div>
                <h2 className="count">3620</h2>
                <span>Shipments done</span>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-count text-center mb-30">
                <div className="count-icon">
                  <i className="pe-7s-portfolio"></i>
                </div>
                <h2 className="count">492+</h2>
                <span>Products</span>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-count text-center mb-30">
                <div className="count-icon">
                  <i className="pe-7s-light"></i>
                </div>
                <h2 className="count">400+</h2>
                <span>Donations</span>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-count text-center mb-30 mrgn-none">
                <div className="count-icon">
                  <i className="pe-7s-smile"></i>
                </div>
                <h2 className="count">582+</h2>
                <span>Happy Customers</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="brand-logo-area pb-100 about-brand-logo">
        <div className="container">
          <div className="brand-logo-active owl-carousel owl-dot-none">
            <div className="single-brand-logo">
              <img src="assets/img/brand-logo/barnd-logo-1.png" alt="" />
            </div>
            <div className="single-brand-logo">
              <img src="assets/img/brand-logo/barnd-logo-2.png" alt="" />
            </div>
            <div className="single-brand-logo">
              <img src="assets/img/brand-logo/barnd-logo-3.png" alt="" />
            </div>
            <div className="single-brand-logo">
              <img src="assets/img/brand-logo/barnd-logo-4.png" alt="" />
            </div>
            <div className="single-brand-logo">
              <img src="assets/img/brand-logo/barnd-logo-5.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default About;
