import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import apiRequestService from "../../core/api-request.service";
import cartService from "../../services/cart.service";
import "./shop.css";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

/**
 * Product detail page.
 * @param {} props
 */
function ProductDetail(props) {
  const [quantities, setQuantities] = useState({});
  const [cartSize, setCartSize] = useState(0);
  const [redirectToLogin, setRedirectToLogin] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState(null);
  //like button state
  const [likedProductIds, setLikedProductIds] = useState([1]);


  /**
   * Event handler for the button quantity adding.
   * @param {string} productId
   * @param {number} quantity
   */

  const addToCart = async (event) => {
    let productId = event.target.getAttribute("data-product-id");
    let quantity = quantities[productId];
    if (productId && quantity && quantity > 0) {
      //send the request to the service.
      let response = await cartService.add({ productId, quantity });
      //we have to update the cartSize also @see cart.service#add.
      if (response?.success) {
        setCartSize(response.data.cartSize);
        props.cartSizeCallback(response.data.cartSize);
        setQuantities({ ...quantities, [productId]: 0 })
      } else {
        setRedirectToLogin(true);
      }
    } else {
      setSelectedProductId(productId);
      setQuantities({ ...quantities, [productId]: 0 })
    }
  };

  /**
   * On Change handler for the select.
   * @param {*} event
   */

  const quantitySelectedHandler = (event) => {
    let productId = event.target.getAttribute("data-product-id");
    let quantity = event.target.value;
    setQuantities({ ...quantities, [productId]: quantity })
    setSelectedProductId(null);
  };
  const selectedProduct = (productId) => {
    return selectedProductId === productId;
  };

  useEffect(() => {
    let initialQuantities = {};
    props?.products?.forEach((product) => (initialQuantities[product.id] = 0));
    setQuantities(initialQuantities);
  }, []);

  const likeHandler = async (product) => {

    setLikedProductIds([...likedProductIds, product._id])

    // there is an issue with this code block so i inserted it on try catch. 
    //if you click the like button once then it throws an error
    try {
      const response = await apiRequestService.post(
        `${process?.env?.REACT_APP_SERVICE_URL}${process?.env?.REACT_APP_LIKE}`,
        { productId: product._id }
      );
      console.log(response);
      console.log(likedProductIds)
    } catch (error) {
      console.error(error)
    }

  };

  return (
    <>
      {redirectToLogin ? <Redirect to="/login" /> : ""}
      {props?.products?.map((product, i) => {
        let discount =
          product.currentPrice === product.previousPrice
            ? "New"
            : `${(
              ((product.currentPrice - product.previousPrice) /
                product.currentPrice) *
              100
            ).toFixed(0)}%`;
        return (

          <React.Fragment key={product._id}>
            {product.picture ? (
              <div
                id={"prod" + product._id}
                className={`col-lg-2 col-sm-12 prod-pic-width ${likedProductIds.includes(product._id) ? 'likedItem' : ''} `}
              >
                <div className="product-wrap scroll-zoom">
                  <div className="product-img">
                    <Link to={{pathname: "/product-detail", state: {product}}}>
                      <img
                        className="default-img"
                        src={product.picture}
                        alt=""
                      />
                      <img className="hover-img" src={product.picture} alt="" />
                    </Link>
                    <span className="pink">{discount}</span>
                    <div className="product-action">
                      <div className="pro-same-action pro-wishlist">
                        <span
                          title="Wishlist"
                          href={void 0}
                          onClick={(e) => {
                            e.preventDefault();
                            likeHandler(product);
                          }}
                        >
                          <i className="pe-7s-like"></i>
                        </span>
                      </div>
                      <div className="pro-same-action pro-cart">
                        <a title="Add To Cart" href="#">
                          <i className="pe-7s-cart"></i> Add to cart
                        </a>
                      </div>
                      <div className="pro-same-action pro-quickview">
                        <a
                          title="Quick View"
                          href="#"
                          data-toggle="modal"
                          data-target="#exampleModal"
                        >
                          <i className="pe-7s-look"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="product-content text-center">
                    <h3>
                      <Link to={{pathname: "/product-detail", state: {product}}}>
                        {product.title}
                      </Link>
                    </h3>
                    <div className="product-rating">
                      <div className="product-rating">
                        {product.rating &&
                          [1, 2, 3, 4, 5].map((rate) => {
                            let rating = rate <= product.rating ? "yellow" : "";
                            return <i className={`fa fa-star-o ${rating}`}></i>;
                          })}
                      </div>
                    </div>
                    <div className="product-price">
                      <span>${product.currentPrice}</span>
                      <span className="old">
                        {product.previousPrice == product.currentPrice
                          ? ""
                          : "$" + product.previousPrice}
                      </span>
                    </div>
                    {/* <div className="row"> */}

                    <div className="row">
                      <select
                        onChange={quantitySelectedHandler}
                        className={`form-control m-2 ${selectedProduct(product._id) ||
                            (product.id === selectedProductId && !quantities[product.id])
                            ? "border-danger" : " "
                          }`}
                        data-product-id={product._id}
                      >
                        <option>Quantity</option>
                        {[...Array(15).keys()].map((quantity) => (
                          <option key={quantity + 1} value={quantity + 1}>
                            {quantity + 1}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="row">
                      <button
                        data-product-id={product._id}
                        onClick={addToCart}
                        className="btn btn-success btn-block m-2"
                      >
                        Add To Cart
                      </button>
                    </div>
                    {/* </div> */}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </React.Fragment>
        );
      })}
    </>
  );
}
export default ProductDetail;
