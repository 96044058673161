import Home from "../home/home";
import About from "../home/About.js";
import Contact from "../home/Contact";
import Shop from "../product/Shop";
import { Switch, Route } from "react-router-dom";
import Login from "../member/login/login";
import VerifyEmail from "../member/verify-email";
import memberService from "../../services/member.service";
import MemberRole from "../../core/member-role";
import AddProduct from "../admin/product/add-product";
import FU from "../upload-file";
import AuthRoute from "../../core/auth-route";
import CartList from "../cart/cart-list";
import EditProduct from "../admin/product/edit-product";
import LikedProducts from "../product/liked-products";
import AddCategory from "../admin/product/add-category";
import Profile from "../member/profile";
import ForgotPassword from "../member/forgot-password";
import Returns from "../home/Returns";
import AddCoupon from "../admin/product/add-coupon";
import ProductDetailView from "../product/product-datail-view.js";

function Content(props) {
  //probably have a better approach for all other case like bidning function.
  const callBackFunction = (value) => {
    props.callBack(value);
  };

  const cartSizeCallback = (data) => {
    props.cartSizeUpdateCallback(data);
  };

  /**
   * Given the string, verify if the currently logged user has the given
   * role or not.
   * @param {string} role
   */
  const isRole = (role) => {
    //get the member data
    let loggedUser = memberService.getLoggedUser();
    if (loggedUser && loggedUser != null && loggedUser != undefined) {
      //verify if the given role is mapped correctly.
      return role.toLowerCase() == loggedUser.role?.toLowerCase();
    }
    return false;
  };
  return (
    <Switch>
      <Route path="/" exact>
        <Home data={props.data} cartSizeCallback={cartSizeCallback} />
      </Route>
      <Route path="/about">
        <About data={props.data} />
      </Route>
      <Route path="/contact">
        <Contact data={props.data} />
      </Route>
      <Route path="/shop">
        <Shop data={props.data} />
      </Route>
      <Route path="/verify-email">
        <VerifyEmail data={props.data} />
      </Route>
      <Route path="/cart">
        <CartList data={props.data} cartSizeCallback={cartSizeCallback} />
      </Route>
      <Route path="/like">
        <LikedProducts data={props.data} />
      </Route>
      <Route path="/login">
        <Login userLogged={props.userLogged} callBack={callBackFunction} />
      </Route>
      <AuthRoute
        path="/profile"
        data={props.data}
        allow={[MemberRole.ADMIN, MemberRole.USER]}
        component={Profile}
      />
      <AuthRoute
        path="/add-coupon"
        data={props.data}
        allow={[MemberRole.ADMIN]}
        component={AddCoupon}
      />
      <AuthRoute
        path="/add-product"
        data={props.data}
        allow={[MemberRole.ADMIN]}
        component={AddProduct}
      />
      <Route path="/forgot-password">
        <ForgotPassword />
      </Route>
      <Route path="/returns">
        <Returns />
      </Route>
      <AuthRoute
        path="/add-product"
        data={props.data}
        allow={[MemberRole.ADMIN]}
        component={AddProduct}
      />
      <Route path="/update-product">
        <EditProduct />
      </Route>
      <Route
        path="/product-detail"
        exact
        render={(props) => <ProductDetailView {...props} />}
      />
      <AuthRoute
        path="/add-category"
        data={props.data}
        allow={[MemberRole.ADMIN]}
        component={AddCategory}
      />
      {/* <Route path="/add-product">
        <AddProduct />
      </Route> */}
      <Route path="/fu" exact>
        <FU />
      </Route>
    </Switch>
  );
}

export default Content;
