/**
 * @author Solution Ladder <code@solutionladder.com>
 */
class MemberRole {
    static GUEST = 'Guest';
    static USER = 'User';
    static ADMIN = 'Admin';
    static VENDOR = 'Vendor';
}

export default MemberRole;