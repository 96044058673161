import { useEffect, useState } from "react";
import { Redirect } from "react-router";
import { useForm } from "react-hook-form";
import memberService from "../../../services/member.service";
import { GoogleLogin, GoogleLogout } from "react-google-login";
import { gapi } from "gapi-script";
import './login.css';

/**
 * Component for the logging to the application
 * The component holds both the login and registration
 * s
 * @author Solution Ladder <code@solutionladder.com>
 */
function Login(props) {
  const [user, setUser] = useState({});
  const [profile, setProfile] = useState([]);
  const [alert, setAlert] = useState({
    style: "",
    message: "",
  });

  const clientId =
    "828738282118-7s2fk83epr6ks50ahghmqlcd30s0kq22.apps.googleusercontent.com";
  useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        clientId: clientId,
        scope: "",
      });
    };
    gapi.load("client:auth2", initClient);
  });

  const {
    handleSubmit: handleSubmitLogin,
    register: registerLogin,
    formState: { errors: errorsLogin },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      loginEmail: "",
      loginPassword: "",
    },
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      email: "",
      password: "",
      gender: "",
      firstName: "",
      lastName: "",
      title: "",
      acceptTerms: "",
    },
  });
  const [logged, setLogged] = useState(false);
  const styles = {
    errorBorder: {
      border: "1px solid red",
    },
    errorMessage: {
      color: "red",
    },
    basic: {},
  };

  async function loginHandler(data) {
    console.log(data);
    const response = await memberService.login(
      data.loginEmail,
      data.loginPassword
    );
    handleLoginResponse(response);
  }

  /**
   * Handles the response from the login attempt
   * @param {object} response
   */
  const handleLoginResponse = (response) => {
    if (response.success) {
      setLogged(true);
      //emit the event here
      props.callBack(true);
    } else {
      setAlert({ message: response.message, style: "alert-danger" });
    }
  };

  /**
   * Event handler for the submit button.
   * @param {*} event
   */
  async function registerHandler(user) {
    const response = await memberService.register(user);
    setAlertMessage(
      response.message,
      response.success ? "alert-success" : "alert-danger"
    );
  }

  /**
   * Common class for setting the alert
   * @todo make the alert consitent throughout by creating component.
   * @param {string} message
   * @param {string} style
   */
  const setAlertMessage = (message, style) => {
    setAlert({ message, style });
  };

  /**
   * Generic function to handle all the input boxes.
   * @param {} event
   */
  const handleInput = (event) => {
    const key = event.target.getAttribute("data-field");
    const type = event.target.getAttribute("type");
    let currentUser = user;
    currentUser[key] = event.target.value;
    if (type == "checkbox") {
      currentUser[key] = event.target.checked;
    }
    setUser(currentUser);
  };

  const onSuccess = async (res) => {
    const response = await memberService.googleAuth(res.tokenId);
    setProfile(res.profileObj);
    handleLoginResponse(response);
  };

  const onFailure = (err) => {
    console.log("failed", err);
  };

  const logOut = () => {
    setProfile(null);
  };

  return (
    <>
      {logged && <Redirect to="/" />}
      <div className="container">
        <div className="row">
          <div className="col-lg-7 col-md-12 ml-auto mr-auto">
            <div className="login-register-wrapper">
              <div className="login-register-tab-list nav">
                <a className="active" data-toggle="tab" href="#lg1">
                  <h4> login </h4>
                </a>
                <a data-toggle="tab" href="#lg2" className="">
                  <h4> register </h4>
                </a>
              </div>
              <div className="tab-content">
                <div id="lg1" className="tab-pane active">
                  <div className="login-form-container">
                    <div className="login-register-form">
                      <div className="row center">
                        <GoogleLogin
                          clientId={clientId}
                          buttonText="Sign in with Google"
                          onSuccess={onSuccess}
                          onFailure={onFailure}
                          cookiePolicy={"single_host_origin"}
                          isSignedIn={true}
                        />
                      </div>
                      <form onSubmit={handleSubmitLogin(loginHandler)}>
                        <div className={"text-center alert " + alert.style}>
                          {alert.message}
                        </div>
                        <input
                          {...registerLogin("loginEmail", {
                            required: "Please enter your email address",
                            pattern: {
                              value:
                                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                              message: "Invalid email address",
                            },
                          })}
                          style={
                            errorsLogin.loginEmail
                              ? styles.errorBorder
                              : styles.basic
                          }
                          type="email"
                          placeholder="Email"
                        />
                        <span style={styles.errorMessage}>
                          {errorsLogin.loginEmail?.message}
                        </span>
                        <input
                          {...registerLogin("loginPassword", {
                            required: "Please enter password",
                          })}
                          style={
                            errorsLogin.loginPassword
                              ? styles.errorBorder
                              : styles.basic
                          }
                          type="password"
                          placeholder="Password"
                        />
                        <span style={styles.errorMessage}>
                          {errorsLogin.loginPassword?.message}
                        </span>
                        <div className="button-box">
                          <div className="login-toggle-btn">
                            <input type="checkbox" />
                            <label>Remember me</label>

                            <a href="/forgot-password">Forgot Password?</a>
                          </div>
                          <button type="submit">
                            <span>Login</span>
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div id="lg2" className="tab-pane">
                  <div className="login-form-container">
                    <div className="login-register-form">
                      <form onSubmit={handleSubmit(registerHandler)}>
                        <div className={"text-center alert " + alert.style}>
                          {alert.message}
                        </div>
                        <span style={styles.errorMessage}>
                          {errors.firstName?.message}
                        </span>
                        <input
                          {...register("firstName", {
                            required: "Please enter your first name",
                          })}
                          style={
                            errors.firstName ? styles.errorBorder : styles.basic
                          }
                          placeholder="First Name"
                        />
                        <span style={styles.errorMessage}>
                          {errors.lastName?.message}
                        </span>
                        <input
                          {...register("lastName", {
                            required: "Please enter your last name",
                          })}
                          style={
                            errors.lastName ? styles.errorBorder : styles.basic
                          }
                          placeholder="Last Name"
                        />
                        <span style={styles.errorMessage}>
                          {errors.gender?.message}
                        </span>
                        <div
                          className="gender"
                          style={
                            errors.gender ? styles.errorBorder : styles.basic
                          }
                        >
                          Male{" "}
                          <input
                            {...register("gender", {
                              required: "Please enter your gender",
                            })}
                            type="radio"
                            value="M"
                          />
                          Female
                          <input
                            {...register("gender", {
                              required: "Please enter your Gender ",
                            })}
                            type="radio"
                            value="F"
                          />
                        </div>
                        <span style={styles.errorMessage}>
                          {errors.email?.message}
                        </span>
                        <input
                          {...register("email", {
                            required: "Please enter your email",
                          })}
                          style={
                            errors.email ? styles.errorBorder : styles.basic
                          }
                          placeholder="Email"
                        />
                        <span style={styles.errorMessage}>
                          {errors.password?.message}
                        </span>
                        <input
                          {...register("password", {
                            required: "Please enter your password ",
                          })}
                          style={
                            errors.password ? styles.errorBorder : styles.basic
                          }
                          placeholder="password"
                          type="password"
                        />

                        <div className="button-box">
                          <div className="login-toggle-btn">
                            <input
                              {...register("acceptTerms", {
                                required: "Please accept the terms",
                              })}
                              style={
                                errors.acceptTerms
                                  ? styles.errorBorder
                                  : styles.basic
                              }
                              type="checkbox"
                              data-field="acceptTerms"
                              onChange={handleInput}
                              name="acceptTerms"
                              value="true"
                            />

                            <label>Accept Terms</label>
                            <br />
                            <span style={styles.errorMessage}>
                              {errors.acceptTerms?.message}
                            </span>
                          </div>

                          <button type="submit">
                            <span>Register</span>
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Login;
