import React from 'react';
import { Route, Redirect } from "react-router-dom";
import memberService from '../services/member.service';
/**
 * Router for authenticated users only
 * 
 * @author Solution Ladder <code@solutionladder.com>
 * @param {component} Component to be redirected to 
 * @param allow - list of roles to be redirected.
 */
const AuthRoute = ({ component: Component, allow, ...rest }) => {
    return <Route {...rest} render={(props) => {
        let combinedProp = {...rest, ...props};
        return memberService.memberHasLogged() &&
            Array.isArray(allow) &&
            allow.includes(memberService.getRole())
            ? <Component {...combinedProp} />
            : <Redirect to='/' />
    }} />
}

export default AuthRoute;