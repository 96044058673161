import { Redirect } from "react-router-dom";
import { useState } from "react";
import { useForm } from "react-hook-form";
import categoryService from '../../../services/category.service';
import './product.css'

/**
 * Admin page for adding new category
 * 
 * @author Solution Ladder <code@solutionladder.com>
 * 
 */
function AddCategory(props) {
    
    const [category, setCategory] = useState({});

    const [alert, setAlert] = useState({
        style: "",
        message: ""
    });

    const { register, handleSubmit, formState: { errors } } = useForm({reValidateMode: "onChange"});

    /**
     * Generic function to handle all the input boxes. 
     * @param {} event 
     */
    const handleInput = event => {
        const key = event.target.getAttribute('data-field');
        let newCategory = category;
        newCategory[key] = event.target.value;
        setCategory(newCategory);
    }

    /**
     * Add the new category to the service.
     */
    const addHandler = async () => {
        let response = await categoryService.add(category);
        if (response.success) {
            setAlert({message: "Successfully Added", style:"alert-success"});
        } else {
            setAlert({message: "Please try again", style:"alert-danger"});
        }
    }
    return (
        <>       
        {props.logged && <Redirect to="/" />}
        <div className="container">
            <div className="row">
                <div className="col-lg-7 col-md-12 ml-auto mr-auto">
                    <div className="login-register-wrapper">
                        <div className="login-register-tab-list nav">
                            <a data-toggle="tab" href="#lg2" className="">
                                <h4> Add Category </h4>
                            </a>
                        </div>
                        {/* <div className="tab-content"> */}
                            <div id="lg2" className="tab-pane">
                                <div className="login-form-container">
                                    <div className="login-register-form">
                                        <form onSubmit={handleSubmit(addHandler)}>
                                            <div className={'text-center alert ' + alert.style}>{alert.message}</div>
                                            <input type="text"
                                            {...register("name", {required: true})}
                                            data-field="name"
                                            onKeyUp={handleInput}
                                            placeholder="Name of Category" />
                                            {errors.name && <span className={'error'}>Name is required</span>}
                                            <textarea data-field="description"
                                            {...register("description", {required: true})}
                                            onChange={handleInput} placeholder="Description"></textarea>
                                            {errors.description && <span className={'error'}>Description is required</span>}
                                            <textarea data-field="tags" 
                                            {...register("tags", {required: true})}
                                            onChange={handleInput} placeholder="Tags"></textarea>
                                            {errors.tags && <span className={'error'}>Tags is required</span>}                                           
                                            <div className="button-box">
                                                <button type="submit"><span className="transform">Add Category</span></button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        {/* </div> */}
                    </div>
                </div>
            </div>
        </div>
    </>
    
        )
}

export default AddCategory;