import memberService from '../services/member.service';
import axios from 'axios';

/**
 * Generic API Request Service for major HTTP methods
 * GET, POST, PUT, DELETE
 * 
 * @author Solution Ladder <code@solutionladder.com>
 */
class ApiRequestService {
    async get(api, withToken = false) {
        let response = {success: false, data: null};
        try {
            response = await this.doActualGet(api, withToken);
            return response;
        } catch (err) {
            //handle the error here.
            //check if token has expired and try one more time after refresh.
            if (err?.response?.data?.code === "invalid_token") {
                response.data = {code: "invalid_token"};
                let tokenRefreshed = await this.refreshToken();
                if (tokenRefreshed) {
                    response = await this.doActualGet(api, withToken);
                    return response;
                } else {
                    response.message = "Please re-login";
                    response.statusCode = 403;
                    return response;
                }
            }
            
        }
    }

        /**
     * Taking out the actual call to the GET server to make room for sending the 
     * request again on failure.
     * @param {*} api 
     * @param {*} data 
     * @param {*} withToken 
     */
    async doActualGet(api, withToken = true) {
        let response = {success: false};
        try {
            let getResponse = await axios.get(
                api,   
                withToken ? this.getTokenPacket() : {}
            );
            if (getResponse) {
                response.success = true;
                response.data = getResponse.data;//this doesn't seem right
                response.message = "Get Successfully";
            }
            return response;
        } catch(error) {
            throw error;
        }
    }

    /**
     * Post handler
     * @param {*} api 
     * @param {*} data 
     */
    async post(api, data, withToken=true) {
        let response = {success: false};
        try {
            let postResponse = await this.doActualPost(api, data, withToken);
            return postResponse;
        } catch(err) {
            //handle the error here.
            //check if token has expired and try one more time after refresh.
            if (err?.response?.data?.code === "invalid_token") {
                response.data = {code: "invalid_token"};
                let tokenRefreshed = await this.refreshToken();
                let postResponse;
                if (tokenRefreshed) {
                    postResponse = await this.doActualPost(api, data, withToken);
                    return postResponse;
                } else {
                    response.statusCode = 403;
                    response.message = "Token expired, needs relogging";
                    return response;
                }
            }
            response.message = "Could not make the post";
        }
        return response;
    }

    /**
     * Taking out the actual call to the server to make room for sending the 
     * request again on failure.
     * @param {*} api 
     * @param {*} data 
     * @param {*} withToken 
     */
    async doActualPost(api, data, withToken = true) {
        let response = {success: false};
        try {
            let postResponse = await axios.post(
                api, 
                data,   
                withToken ? this.getTokenPacket() : {}
            );
            if (postResponse) {
                response.success = true;
                response.data = postResponse.data.data;//this doesn't seem right
                response.message = "Successfully Posted";
            }
            return response;
        } catch(error) {
            throw error;
        }
    }

    /**
     * Put handler
     * @param {*} api 
     * @param {*} data 
     */
    async put(api, data, withToken=true) {
        let response = {success: false};
        try {
            let putResponse = await this.doActualPut(api, data, withToken);
            return putResponse;
        } catch(err) {
            //handle the error here.
            //check if token has expired and try one more time after refresh.
            if (err?.response?.data?.code === "invalid_token") {
                response.data = {code: "invalid_token"};
                let tokenRefreshed = await this.refreshToken();
                let putResponse;
                if (tokenRefreshed) {
                    putResponse = await this.doActualPut(api, data, withToken);
                    return putResponse;
                } else {
                    response.statusCode = 403;
                    response.message = "Token expired, needs relogging";
                    return response;
                }
            }
            response.message = "Could not make the put";
        }
        return response;
    }

    /**
     * Taking out the actual call to the server to make room for sending the 
     * request again on failure.
     * @param {*} api 
     * @param {*} data 
     * @param {*} withToken 
     */
    async doActualPut(api, data, withToken = true) {
        let response = {success: false};
        try {
            let putResponse = await axios.put(
                api, 
                data,   
                withToken ? this.getTokenPacket() : {}
            );
            if (putResponse) {
                response.success = true;
                response.data = putResponse.data.data;//this doesn't seem right
                response.message = "Successfully Posted";
            }
            return response;
        } catch(error) {
            throw error;
        }
    }    

    /**
     * Additional token packets needed to for the server to be sent to.
     */
    getTokenPacket() {
        let token = memberService.getLoggedUser()?.jwtToken;
        return {
            headers: {
                Authorization: `Bearer ${token}`
            },
            withCredentials: true
        }
    }

    /**
     * Refresh the security token as needed. 
     */
    async refreshToken() {
        let refreshToken = memberService.getLoggedUser().refreshToken;
        let user = await this.post(
            `${process?.env?.REACT_APP_SERVICE_URL}/api/account/refresh-token`,
            {refreshToken},
            true
        );
        if (user.success && user.data) {
            memberService.cacheUser(user.data);
            return true;
        }
        return false;
    }
}

const apiRequestService = new ApiRequestService();
export default apiRequestService;