import React from "react";
/**
 * Component for resetting the password 
 */
function ForgotPassword(){ 
return(
<div className="container">
    <div className="row">
        <div className="col-lg-7 col-md-12 ml-auto mr-auto">
            <div className="login-register-wrapper">
                <div className="login-register-tab-list nav">
                <label>Reset Password </label> 
                </div>
                <div className="tab-content">
                        <div id="lg1" className="tab-pane active">
        <div className="login-form-container">
            <div className="login-register-form">
                <form>
                <div className={'text-center alert ' + alert.style}>{alert.message}</div>
                <input 
                  type="email" placeholder="Email" 
                    />
                    <div className="button-box">
                       <button type="submit"><span>Submit</span></button>
                                     </div>
                                        </form> 
                                        </div>
                                        </div>
                                          </div>
                                          </div>
                                        </div>
                                     </div>
                                     </div>
                                     </div>
                                     );
                                        }
export default ForgotPassword;