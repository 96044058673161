/**
 * @author Practical IT <info@thepracticalit.com>
 */
import axios from 'axios'
import {useEffect, useState} from 'react';

function VerifyEmail(props) {
    const [message, setMessage] = useState("");
    const [error, isError] = useState("");

    const sendVerification = () => {
        const token = (new URLSearchParams(window.location.search))?.get("token");
        //make sure the toke is passed
        if (token && token.length > 0) {
            let url = `${process?.env?.REACT_APP_SERVICE_URL}${process?.env?.REACT_APP_VERIFY_EMAIL}`
            url += `?token=${token}`;
            axios.get(url)
            .then( data => {
                setMessage(data?.data?.message);
            })
            .catch( error => {
                //error format specific to all?
                setMessage(error.response.data.message);
                isError(true);
            })
        } else {
            setMessage("Invalid token");
            isError(true);
        }
    }

    useEffect(() => {
        sendVerification();
        return () => {
            //cleanup
        }
    }, [])
    return (
        <div className="container">
            <div className={"alert alert-" + (error ? "danger": "info")}>{message}</div>
        </div>
    )
}

export default VerifyEmail;