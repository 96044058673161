import axios from 'axios';

/**
 * I8TN related services 
 * 
 * @author Solution Ladder <code@solutionladder.com>
 */

class I8tnService {
    
    /**
     * @todo make this to be cached locally than reading all the time.
     * @param {string} language 
     */
    async get(language="en") {
        let url = `${process?.env?.REACT_APP_SERVICE_URL}${process?.env?.REACT_APP_LANG}`;
        url = url.replace(':lang', language);
        return axios.get(url);
    }
}

const i8tnService = new I8tnService();

export default i8tnService;