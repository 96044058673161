import React, { useState } from "react";
import axios from "axios";

function FU() {
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState("");

  const saveFile = (e) => {
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };

  const uploadFile = async (e) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", fileName);
    formData.append("title", "gerami");
    try {
      await axios.post(
        "http://localhost:3300/upload",
        formData
      );
    } catch (ex) {
      console.log(ex);
    }
  };

  return (
    <div className="App">
        <h1>Having this in mind</h1>

      <input type="file" onChange={saveFile} />
      <button onClick={uploadFile}>Upload</button>
    </div>
  );
}

export default FU;