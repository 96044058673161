import { useState, useEffect } from "react";
import memberService from "../../services/member.service";
import i8tnService from "../../services/i8tn.service";
import * as React from 'react';
import Hamburger from "./Hamburger";
const { Link } = require("react-router-dom");

function Header(props) {
  const [state, setState] = useState({
    lang: {},
    style: {
      showLanguage: {
        display: "none",
      },
    },
  });
  // State to control the visibility of the mobile menu
  const [showMenu, setShowMenu] = useState(false);
  // State to track the window width using 'window.innerWidth'
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
// Effect hook to populate language and handle window resize
  useEffect(() => {
    populateLang();
    const handleResize = () => {
      // Updates window width on resize
      setWindowWidth(window.innerWidth);
    };
    // Adds event listener for window resize
    window.addEventListener("resize", handleResize);
  // Remove the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
   // Function to toggle mobile menu visibility
   const toggleMenu = () => {
    // Toggles the showMenu state between true and false
    setShowMenu(!showMenu);
  };

  const populateLang = async () => {
    let lang = await i8tnService.get();
    let currentState = { ...state };
    currentState.lang = lang.data;
    setState(currentState);
  };

  const showHideLanguage = (event) => {
    event.preventDefault();
    let currentState = { ...state };
    let display = state.style.showLanguage.display;
    currentState.style.showLanguage = {
      display: display == "block" ? "none" : "block",
    };
    setState(currentState);
  };

  /**
   * Verify if the menu for admin is allowed or not.
   * @param none
   * @return boolean - true for admin user false otherwise.
   */
  const showAdminMenu = () => {
    return memberService.isMemberAdmin();
  };

  /**
   * Handler for the logout method
   */
  const handleLogout = (event) => {
    event.preventDefault();
    memberService.logout();
    //set the currrent user logged info to false
    props.callBack(false);
  };

  return (
    <header className="header-area clearfix">
      <div className="header-top-area header-padding-2">
        <div className="container-fluid">
          <div className="header-top-wap">
            <div className="language-currency-wrap">
              <div className="same-language-currency language-style">
                <a onClick={showHideLanguage} href="#">
                  English <i className="fa fa-angle-down"></i>
                </a>
                <div
                  className="lang-car-dropdown"
                  style={state.style.showLanguage}
                >
                  <ul>
                    {props.data?.languages?.map((lang, i) => {
                      return (
                        <li key={i}>
                          <a href="#">{lang.label} </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
              <div className="same-language-currency use-style">
                <a href="#">
                  USD <i className="fa fa-angle-down"></i>
                </a>
                <div className="lang-car-dropdown">
                  <ul>
                    {props?.data?.currencies?.map((currency, i) => {
                      return (
                        <li key={i}>
                          <a href="#">{currency.label}</a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
              <div className="same-language-currency">
                <p>Call {props.data?.contact?.phone1}</p>
              </div>
            </div>
            {props.userLogged && (
              <div className="">
                <p className="greeting">
                  Hi, {memberService?.getLoggedUser()?.firstName.toLowerCase()}
                </p>
              </div>
            )}
            <div className="header-offer">
              <p>
                {props.data?.deliveryInfo?.label}{" "}
                <span>${props.data?.deliveryInfo?.over}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="header-bottom sticky-bar header-res-padding header-padding-2">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-2 col-lg-2 col-md-6 col-4">
              <div className="logo">
                <Link to="/">
                <img alt="" className="logo-image" src="assets/img/logo/logo.jpg" />
                </Link>
              </div>
            </div>
             {/* Hamburger menu for screens less than 991px */}
             {/* // Conditionally render for window widths less than or equal to 991px */}
             {windowWidth <= 991 ? (
        <div className="col-12 d-lg-none">
          <div className="hamburger-container">
            <button className="hamburger-button" onClick={toggleMenu}>
              ☰
            </button>
          </div>
           {/* Render the Hamburger component if showMenu state is true */}
          {showMenu && <Hamburger data={props.data} />}

        </div>
            ):(
                // Render when the window width is greater than 991px
              <>
            <div className="col-xl-8 col-lg-8 d-none d-lg-block">
                <div className="main-menu">
                  <nav>
                  <ul>
                    <li>
                      <Link to="/">{props?.data?.i8tn?.en?.home}</Link>
                    </li>

                    <li>
                      <Link to="/shop">
                        {props?.data?.i8tn?.en?.collection}
                      </Link>
                    </li>
                    <li>
                      <Link to="/about">{props?.data?.i8tn?.en?.about}</Link>
                    </li>
                    <li>
                      <Link to="/contact">
                        {props?.data?.i8tn?.en?.contact}
                      </Link>
                    </li>
                    {showAdminMenu() && (
                      <li>
                        <div className="dropdown">
                          <button
                            className="btn btn-light btn-sm dropdown-toggle"
                            type="button"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            Admin
                          </button>
                          <div
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton"
                          >
                            <Link className="dropdown-item" to="/add-coupon">
                              Add Coupon
                            </Link>
                            <Link className="dropdown-item" to="/add-category">
                              Add Category
                            </Link>
                            <Link className="dropdown-item" to="/add-product">
                              Add Product
                            </Link>
                            <Link
                              className="dropdown-item"
                              to="/update-product"
                            >
                              Update Product
                            </Link>
                            <Link
                              className="dropdown-item"
                              to="/Search Member"
                            >
                              Search Member
                            </Link>
                            <a className="dropdown-item" to="/update-member">
                              Update Member
                            </a>
                          </div>
                        </div>
                      </li>
                    )}
                  </ul>
                  </nav>
              </div>
            </div>
          </>
          )}
            <div className="col-xl-2 col-lg-2 col-md-6 col-8">
              <div className="header-right-wrap">
                <div className="same-style header-search">
                  <a className="search-active" href="#">
                    <i className="pe-7s-search"></i>
                  </a>
                  <div className="search-content">
                    <form action="#">
                      <input type="text" placeholder="Search" />
                      <button className="button-search">
                        <i className="pe-7s-search"></i>
                      </button>
                    </form>
                  </div>
                </div>
                {!props.userLogged && (
                  <div className="same-style">
                    <Link className="account-satting-active" to="/login">
                      <i className="pe-7s-door-lock"></i>
                    </Link>
                  </div>
                )}
                {props.userLogged && (
                  <>
                    <div className="same-style account-satting">
                      <Link className="account-satting-active" to="/profile">
                        <i
                          className={
                            memberService.getLoggedUser().gender == "F"
                              ? "pe-7s-user-female"
                              : "pe-7s-user"
                          }
                        ></i>
                      </Link>
                      <div className="account-dropdown">
                        <ul>
                          <li>
                            <a href="">Login</a>
                          </li>
                          <li>
                            <a href="">Register</a>
                          </li>
                          <li>
                            <a href="">Wishlist </a>
                          </li>
                          <li>
                            <a href="">my account</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="same-style header-wishlist">
                      <Link to="/like">
                        <i className="pe-7s-like"></i>
                      </Link>
                    </div>
                    <div className="same-style header-wishlist">
                      <a href="" onClick={handleLogout}>
                        <i className="pe-7s-power"></i>
                      </a>
                    </div>
                  </>
                )}
                {memberService.getLoggedUser() && (
                  <div className="same-style cart-wrap">
                    <Link to="/cart">
                      <button className="icon-cart">
                        <i className="pe-7s-shopbag"></i>
                        <span className="count-style">
                          {props.cartSize === 0
                            ? memberService.getLoggedUser()?.cartSize
                            : props.cartSize}
                        </span>
                      </button>
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
