import { Redirect } from "react-router-dom";
import { useState } from "react";
import { useForm } from "react-hook-form";
import "./product.css";
import couponService from "./../../../services/coupon.service";

/**
 * Admin page for adding new coupon.
 */
function AddCoupon(props) {
  const [coupon, setCoupon] = useState({});

  const [alert, setAlert] = useState({
    style: "",
    message: "",
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ reValidateMode: "onChange" });

  /**
   * Generic function to handle all the input boxes.
   * @param {} event
   */
  const handleInput = (event) => {
    const key = event.target.getAttribute("data-field");
    let newCoupon = coupon;
    newCoupon[key] = event.target.value;
    setCoupon(newCoupon);
  };

  /**
   * Add new coupon.
   */
  const addHandler = async () => {
    let file = new FormData();

    file.append("code", coupon.code);
    file.append("description", coupon.description);
    file.append("startDate", coupon.startDate);
    file.append("endDate", coupon.endDate);
    file.append("percentage", coupon.percentage);
    let response = await couponService.add(file);
    console.log(response);
    if (response && response.data?.errors == null) {
      setAlert({ style: "alert alert-success", message: "Successfully Added" });
    } else {
      setAlert({ style: "alert alert-danger", message: "It didn't go thru." });
    }
    //check for the success message before wiping the data.
    if (response && response.data?.errors == null) {
      reset({
        code: "",
        description: "",
        startDate: "",
        endDate: "",
        percentage: "",
      });
    }
  };
  return (
    <>
      {props.logged && <Redirect to="/" />}
      <div className="container">
        <div className="row">
          <div className="col-lg-7 col-md-12 ml-auto mr-auto">
            <div className="login-register-wrapper">
              <div className="login-register-tab-list nav">
                <a data-toggle="tab" href="#lg2" className="">
                  <h4> Add Coupon </h4>
                </a>
              </div>
              <div id="lg2" className="tab-pane">
                <div className="login-form-container">
                  <div className="login-register-form">
                    <form onSubmit={handleSubmit(addHandler)}>
                      <div className={"text-center alert " + alert.style}>
                        {alert.message}
                      </div>
                      <input
                        type="text"
                        {...register("code", { required: true })}
                        data-field="code"
                        onKeyUp={handleInput}
                        placeholder="code"
                      />
                      {errors.title && (
                        <span className={"error"}>Code is required</span>
                      )}
                      <textarea
                        data-field="description"
                        {...register("description", { required: true })}
                        onChange={handleInput}
                        placeholder="Description"
                      ></textarea>
                      {errors.description && (
                        <span className={"error"}>Description is required</span>
                      )}
                      <input
                        type="date"
                        {...register("startDate", { required: true })}
                        data-field="startDate"
                        onChange={handleInput}
                        placeholder="startDate"
                      />
                      {errors.currentPrice && (
                        <span className={"error"}>Start Date is required</span>
                      )}
                      <input
                        type="date"
                        {...register("endDate", { required: true })}
                        data-field="endDate"
                        onChange={handleInput}
                        placeholder="endDate"
                      />
                      {errors.previousPrice && (
                        <span className={"error"}>End Date is required</span>
                      )}
                      <input
                        type="number"
                        {...register("percentage", { required: true })}
                        data-field="percentage"
                        onChange={handleInput}
                        placeholder="percentage"
                        min="0"
                      />
                      {errors.previousPrice && (
                        <span className={"error"}>
                          Percentage amount is required
                        </span>
                      )}

                      <div className="button-box">
                        <button type="submit">
                          <span className="transform">Add Coupon</span>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddCoupon;
